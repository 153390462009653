import React from 'react';
import {graphql} from 'gatsby';

import '@evag/ec-react/dist/main.css';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Hero from "../components/Hero";

const NotFound = ({data}) => {
    return (<Layout>
            <SEO title="404 - Ops!"/>

            <Hero
                background={data.hero.childImageSharp.gatsbyImageData}
                title={data.site.meta.title}
                description={data.site.meta.description}
                channel={data.site.meta.channel}
            />

            <main className="not-found">
                <div className="wrap text-center">
                    <h1>404</h1>
                    <strong className="face-chars">:(</strong>
                    <h2>Ops! A página que você está procurando não existe.</h2>
                </div>
            </main>
        </Layout>
    )};

export default NotFound;

export const query = graphql`
  query {
    site {
      meta: siteMetadata {
        title
        description
        channel
        url
      }
    }

    hero: file(relativePath: { eq: "og-image.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920)
      }
    }
  }
`;
